import React from "react";
import ReactDOM from "react-dom/client";
import "../src/assets/style/index.css";
import App from "./components/telas/App";
import Estabelecimento from "./components/telas//estabelecimento/Estabelecimento";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./components/telas/login/Login";
import MinhaLoja from "./components/telas/minha-loja/MinhaLoja";
import Produtos from "./components/telas/cardapio/Produtos";
import EditarItemCardapio from "./components/telas/cardapio/editar-criar-item-cardapio/EditarItemCardapio";
import Cadastro from "./components/telas/login/formulario-cadastro/Cadastro.js";
import EsqueciminhaSenha from "./components/telas/login/esqueci-minha-senha/EsqueciMinhaSenha";
import NossaMissao from "./components/telas/nossa-missao/NossaMissao";
import Entregas from "./components/telas/entregas/Entregas.js";
import Pedidos from "./components/telas/pedidos/Pedidos";
import HomeEstabelecimento from "./components/telas/area-estabelecimento/HomeEstabelecimento";
import DetalheDePedido from "./components/telas/cardapio/DetalheDePedido";
import PoliticaDePrivacidade from "./components/telas/politica-de-privacidade/PoliticaDePrivacidade";
import ExcluirConta from "./components/telas/politica-de-privacidade/ExcluirConta.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  { 
    path: "*",
   element: <App /> },
  {
    path: "/estabelecimentos",
    element: <Estabelecimento />,
  },
  {
    path: "/portal/login",
    element: <Login />,
  },
  {
    path: "/portal/estabelecimentos/cadastro",
    element: <Cadastro />,
  },
  {
    path: "/portal/estabelecimentos/esqueciasenha",
    element: <EsqueciminhaSenha />,
  },
  {
    path: "/portal/estabelecimentos/inicio",
    element: <HomeEstabelecimento />,
  },
  {
    path: "/portal/estabelecimentos/minhaloja",
    element: <MinhaLoja />,
  },
  {
    path: "/portal/estabelecimentos/produtos",
    element: <Produtos />,
  },
  {
    path: "/portal/estabelecimentos/produto/edicao",
    element: <EditarItemCardapio />,
  },
  {
    path: "/estabelecimentos/esqueciasenha",
    element: <EsqueciminhaSenha />,
  },

  {
    path: "/portal/estabelecimentos/pedidos",
    element: <Pedidos />,
  },
  {
    path: "/portal/estabelecimentos/pedidos/detalhes",
    element: <DetalheDePedido />,
  },
  {
    path: "/pp",
    element: <PoliticaDePrivacidade/>,
  },
  {
    path: "/excluir",
    element: <ExcluirConta/>
  },
  {
    path: "/portal/estabelecimentos/entregas",
    element: <Entregas/>,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
